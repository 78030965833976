import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import ExtractedTab from './TabsContent/ExtractedTab';
import ReviewTab from './TabsContent/ReviewTab';
import FieldsManagementTab from './TabsContent/FieldsManagementTab';
import TabPanel from './TabsContent/TabPanel';

function MainContent({ fields, setFields, extractedData, setExtractedData, reviewData, setReviewData }) {
  const savedTabIndex = localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex'), 10) : 0;
  const [tabIndex, setTabIndex] = useState(savedTabIndex);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    localStorage.setItem('tabIndex', newValue);
  };

  return (
    <Box sx={{ flex: 1, p: 2, overflowY: 'auto' }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Fields Management" />
        <Tab label="Extracted" />
        <Tab label="Review" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <FieldsManagementTab fields={fields} setFields={setFields} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ExtractedTab
          fields={fields}
          extractedData={extractedData}
          setExtractedData={setExtractedData}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ReviewTab
          fields={fields}
          reviewData={reviewData}
          setReviewData={setReviewData}
        />
      </TabPanel>
    </Box>
  );
}

export default MainContent;