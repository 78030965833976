import React from 'react';
import DataTable from 'react-data-table-component';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { downloadData } from '../../utils/dataUtils';
import { clearExtractedData, saveExtractedData } from '../../db';

const customStyles = {
  header: {
    style: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#4a4a4a',
      backgroundColor: '#f5f5f5',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#e0e0e0',
    },
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#4a4a4a',
    },
  },
  rows: {
    style: {
      fontSize: '14px',
      color: '#333',
      backgroundColor: '#fff',
      borderBottom: '1px solid #ddd',
      '&:hover': {
        backgroundColor: '#f9f9f9',
      },
    },
  },
};

function ExtractedTab({ fields = [], extractedData = [], setExtractedData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDownloadMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (format) => {
    downloadData(extractedData, fields, format, 'extracted_data');
    handleDownloadMenuClose();
  };

  const handleClearData = async () => {
    setExtractedData([]);
    await clearExtractedData();
    console.log('Extracted data cleared.');
  };

  const handleCellEdit = async (row, field, value) => {
    const updatedData = extractedData.map((item) =>
      item.id === row.id ? { ...item, [field]: value } : item
    );
    setExtractedData(updatedData);
    await saveExtractedData(updatedData); // Persist changes
  };

  const columns = [
    { name: 'File Name', selector: (row) => row.fileName, sortable: true },
    ...fields.map((field) => ({
      name: field,
      selector: (row) => row[field] || 'N/A',
      sortable: true,
      cell: (row) => (
        <input
          type="text"
          defaultValue={row[field]}
          onBlur={(e) => handleCellEdit(row, field, e.target.value)}
          style={{
            width: '100%',
            padding: '5px',
            border: '1px solid #ddd',
            borderRadius: '4px',
          }}
        />
      ),
    })),
  ];

  return (
    <Box sx={{ mt: 4, p: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button
            variant="outlined"
            onClick={handleDownloadMenuOpen}
            sx={{ mr: 2 }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Download As...
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDownloadMenuClose}
          >
            <MenuItem onClick={() => handleDownload('csv')}>CSV</MenuItem>
            <MenuItem onClick={() => handleDownload('json')}>JSON</MenuItem>
            <MenuItem onClick={() => handleDownload('xlsx')}>XLSX</MenuItem>
            <MenuItem onClick={() => handleDownload('pdf')}>PDF</MenuItem>
            <MenuItem onClick={() => handleDownload('xml')}>XML</MenuItem>
            <MenuItem onClick={() => handleDownload('sql')}>SQL</MenuItem>
          </Menu>
        </div>
        <Button variant="outlined" color="error" onClick={handleClearData}>
          Clear Data
        </Button>
      </Box>
      <DataTable
        title="Extracted Data"
        columns={columns}
        data={extractedData}
        customStyles={customStyles}
        pagination
        highlightOnHover
        responsive
      />
    </Box>
  );
}

export default ExtractedTab;