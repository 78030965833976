import Dexie from 'dexie';

const db = new Dexie('DocumentProcessingDB');

// Increment the version number when making schema changes
db.version(5).stores({
  fields: '&id, name',
  extractedData: '&id',
  reviewData: '&id',
});

// Save reviewData using 'id's from data
export const saveReviewData = async (data) => {
  try {
    await db.reviewData.bulkPut(data); // Use bulkPut to preserve 'id's
  } catch (error) {
    console.error('Error saving reviewData:', error);
  }
};

export const loadReviewData = async () => {
  try {
    return await db.reviewData.toArray();
  } catch (error) {
    console.error('Error loading reviewData:', error);
    return [];
  }
};

// Save fields as array of objects with 'id' and 'name' properties
export const saveFields = async (fields) => {
  try {
    const fieldObjects = fields.map((name) => ({
      id: name.replace(/\s+/g, '').toLowerCase(),
      name,
    }));

    await db.fields.bulkPut(fieldObjects);

    // Delete fields that are no longer in the fields array
    const existingFieldIds = (await db.fields.toCollection().primaryKeys());
    const fieldIdsToDelete = existingFieldIds.filter(
      (id) => !fieldObjects.find((fieldObj) => fieldObj.id === id)
    );
    if (fieldIdsToDelete.length > 0) {
      await db.fields.bulkDelete(fieldIdsToDelete);
    }
  } catch (error) {
    console.error('Error saving fields:', error);
  }
};

// Load fields and deduplicate them before returning
export const loadFields = async () => {
  try {
    const fieldObjects = await db.fields.toArray();
    return fieldObjects.map((fieldObj) => fieldObj.name);
  } catch (error) {
    console.error('Error loading fields:', error);
    return [];
  }
};

export const saveExtractedData = async (data) => {
  try {
    // Update or add each item individually to avoid primary key conflicts
    for (const item of data) {
      await db.extractedData.put(item);
    }
    console.log('Saved extracted data to IndexedDB:', data);
  } catch (error) {
    console.error('Error saving extracted data to IndexedDB:', error);
  }
};

export const loadExtractedData = async () => {
  try {
    const data = await db.extractedData.toArray();
    console.log('Data loaded from IndexedDB:', data);
    return data;
  } catch (error) {
    console.error('Error loading extractedData:', error);
    return [];
  }
};

// Remove a field from IndexedDB by name
export const deleteFieldByName = async (fieldName) => {
  try {
    const fieldId = fieldName.replace(/\s+/g, '').toLowerCase();
    await db.fields.delete(fieldId);
    console.log(`Deleted field from IndexedDB: ${fieldName}`);
  } catch (error) {
    console.error('Error deleting field:', error);
  }
};

// Clear extractedData from IndexedDB
export const clearExtractedData = async () => {
  try {
    await db.extractedData.clear();
  } catch (error) {
    console.error('Error clearing extractedData:', error);
  }
};

// Clear reviewData from IndexedDB
export const clearReviewData = async () => {
  try {
    await db.reviewData.clear();
  } catch (error) {
    console.error('Error clearing reviewData:', error);
  }
};

// Clear all data from IndexedDB (fields, extractedData, and reviewData)
export const clearIndexedDB = async () => {
  try {
    await db.fields.clear();
    await db.extractedData.clear();
    await db.reviewData.clear();
  } catch (error) {
    console.error('Error clearing IndexedDB:', error);
  }
};