import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  Badge,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUpload';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AccountPage() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null); // Track errors
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);
  const [issueDescription, setIssueDescription] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const FREE_UPLOADS = 50; // Set the number of free uploads in Sidebar.js and /process of routes.py as well

  useEffect(() => {
    const token = localStorage.getItem('token'); // Get the token from local storage

    // if token is not found or has expired, redirect to landing page
    if (!token) {
      navigate('/');
      return;
    }

    const fetchAccountData = async () => {
      try {
        const response = await axios.get('https://api.idp.aiwizz.io/account', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data); // Set the user data from the API response
      } catch (err) {
        console.error('Error fetching account data:', err);
        setError('Failed to load account details.');
      }
    };

    fetchAccountData();
  }, [navigate]);

  if (error) {
    return <Typography variant="h6">{error}</Typography>; // Display error message if data fetch fails
  }

  if (!user) {
    return <Typography variant="h6">Loading account details...</Typography>; // Show loading state
  }

  // Calculate remaining free uploads
  const remainingUploads = Math.max(0, FREE_UPLOADS - user.request_count);

  // Format spending values to two decimal places
  const formattedCurrentSpending = (user.current_spending || 0).toFixed(2);
  const formattedTotalSpent = (user.total_spent || 0).toFixed(2);

  // Handle support dialog open/close
  const handleSupportDialogOpen = () => {
    setSupportDialogOpen(true);
  };

  const handleSupportDialogClose = () => {
    setSupportDialogOpen(false);
    setIssueDescription('');
  };

  // Handle support form submission
  const handleSupportSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        'https://api.idp.aiwizz.io/support',
        { issueDescription },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarSeverity('success');
      setSnackbarMessage('Your issue has been submitted successfully.');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting support issue:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to submit your issue. Please try again later.');
      setSnackbarOpen(true);
    } finally {
      handleSupportDialogClose();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      marginTop={-15}
      p={3}
    >
      <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
        {user.fullname.charAt(0).toUpperCase()}
      </Avatar>
      <Typography variant="h4">{user.fullname}</Typography>
      <Typography variant="h6" color="textSecondary" marginBottom={5}>
        {user.email}
      </Typography>
      <Tooltip title="Number of Free Uploads Remaining">
        <Badge badgeContent={remainingUploads} color="primary" showZero>
          <DriveFolderUploadRoundedIcon fontSize="large" />
        </Badge>
      </Tooltip>
      <Typography variant="h6" color="textSecondary" marginTop={3}>
        Total Amount Spent: ${formattedTotalSpent}
      </Typography>
      <Typography variant="h6" color="textSecondary" marginTop={1}>
        Current Spending This Month: ${formattedCurrentSpending}
      </Typography>
      <Typography variant="h6" color="textSecondary" marginTop={1}>
        Billing Cycle: {user.billing_cycle_start} - {user.billing_cycle_end}
      </Typography>
      <Box display="flex" flexDirection="row" gap={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/payment-setup')}
        >
          Update Payment Method
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleSupportDialogOpen}>
          Support
        </Button>
      </Box>

      {/* Support Dialog */}
      <Dialog open={supportDialogOpen} onClose={handleSupportDialogClose}>
        <DialogTitle>Support</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Please describe the issue you're experiencing:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Issue Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSupportDialogClose}>Cancel</Button>
          <Button
            onClick={handleSupportSubmit}
            variant="contained"
            color="primary"
            disabled={!issueDescription.trim()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountPage;